import React, { useState } from 'react';
import { Box, Heading, Text, TextButton } from '@wix/design-system';
import type { BenefitType } from '../../components/benefit';
import { ExternalLinkSmall } from '@wix/wix-ui-icons-common';
import {
  useEssentials,
  withEssentialsContext,
} from '../../contexts/essentials-context';
import type { ExternalComponentProps } from '../types';
import css from './lean-app-overview.scss';
import classNames from 'classnames';
import { LeanAppOverviewDataHooks } from './data-hooks';

export interface LeanAppOverviewProps extends ExternalComponentProps {
  rows?: number;
  appName: string;
  learnMoreHref: string;
  benefits?: BenefitType[];
  onExpandToggleClick?: () => void;
}

const LeanAppOverviewComponent = ({
  appName,
  benefits,
  rows = 8,
  learnMoreHref,
  onExpandToggleClick,
}: LeanAppOverviewProps) => {
  const { t } = useEssentials();
  const [isTextCollapsed, setIsTextCollapsed] = useState(true);
  const [shouldShowExpandButton, setShouldShowExpandButton] = useState(false);

  if (appName === '') {
    return <></>;
  }

  const toggleTextExpanding = () => {
    setIsTextCollapsed((prevState) => !prevState);
    onExpandToggleClick?.();
  };

  const saveEllipsisState = (value: boolean) => {
    // We want to save the text state. If it has ellipsis we want to show the read more/less button.
    // If it doesn't have ellipsis we don't want to show the button.
    setShouldShowExpandButton((prevState) => prevState || value);
  };

  return (
    <Box direction="vertical" gap="9px" dataHook={LeanAppOverviewDataHooks.BOX}>
      <Heading size="medium" dataHook={LeanAppOverviewDataHooks.APP_NAME}>
        {t('app.page.overview.title', {
          name: appName,
          interpolation: { escapeValue: false },
        })}
      </Heading>

      <Text
        ellipsis
        size="small"
        showTooltip={false}
        maxLines={isTextCollapsed ? rows : 100}
        onEllipsisStateChange={saveEllipsisState}
        dataHook={LeanAppOverviewDataHooks.BENEFITS_LIST}
        className={classNames({
          [css.benefitsText]: shouldShowExpandButton && isTextCollapsed,
        })}
      >
        <ul data-hook="benefits-list">
          {benefits?.map(
            (benefit) => benefit.title && <li>{benefit.title}</li>,
          )}
        </ul>

        <Box inline margin="3px 0 0 24px">
          <TextButton
            as="a"
            skin="dark"
            size="small"
            target="_blank"
            underline="always"
            href={learnMoreHref}
            suffixIcon={<ExternalLinkSmall />}
            dataHook={LeanAppOverviewDataHooks.LEARN_MORE_BUTTON}
          >
            {t('app.page.overview.learnMore', {
              appName,
              interpolation: { escapeValue: false },
            })}
          </TextButton>
        </Box>
      </Text>

      {shouldShowExpandButton && (
        <Box marginLeft="SP4">
          <TextButton size="small" onClick={toggleTextExpanding}>
            {isTextCollapsed
              ? t('app.page.overview.readMore')
              : t('app.page.overview.readLess')}
          </TextButton>
        </Box>
      )}
    </Box>
  );
};

export const LeanAppOverview = withEssentialsContext(LeanAppOverviewComponent);
