import React from 'react';
import { Box } from '@wix/design-system';
import { AppGallery, LeanAppOverview } from '@wix/app-market-components';
import type { getAppPageDataResponse } from '../app-page-api';
import { useQuickInfoActions } from '../app-page-sections/quick-info/quick-info-actions';
import { AppPermissionsAccordion } from './app-permissions-accordion/app-permissions-accordion';
import css from './lean-app-page-info.module.scss';
import { Route, RoutePath } from '../../../models';
import { useSiteContext } from '../../../contexts';

type LeanAppPageInfoProps = Pick<
  getAppPageDataResponse,
  'app' | 'overview' | 'permissions' | 'quickInfo'
>;

export function LeanAppPageInfo({
  app,
  overview,
  quickInfo,
  permissions,
}: LeanAppPageInfoProps) {
  const { onGalleryChange, onExpandDescriptionButtonClick } =
    useQuickInfoActions();
  const { baseURL } = useSiteContext();

  const appPageRoute = new Route({
    baseURL,
    path: RoutePath.APP_PAGE,
    payload: { slug: app.slug },
  });

  return (
    <Box className={css.container}>
      <Box direction="vertical" gap="SP4" className={css.appInfo}>
        <LeanAppOverview
          appName={app.name}
          benefits={overview.benefits}
          learnMoreHref={appPageRoute.toHref()}
          onExpandToggleClick={() => onExpandDescriptionButtonClick?.(app.id)}
        />

        {permissions?.length && (
          <AppPermissionsAccordion
            appName={app.name}
            permissions={permissions}
          />
        )}
      </Box>

      <div className={css.appGallery}>
        <AppGallery
          media={quickInfo.media}
          onGalleryChange={(oldIndex, newIndex) =>
            onGalleryChange(oldIndex, newIndex, app.id)
          }
        />
      </div>
    </Box>
  );
}
