import React, { useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import { i18nInit } from './i18nInit-browser';
import { useConfigContext } from '../config-context';
import { useExperiments } from '@wix/wix-experiments-react';
import { CenteredLoader } from '../components/centered-loader';

export const TranslationsProvider = ({
  children,
  locale,
}: {
  children: any;
  locale?: string;
}) => {
  const { experiments } = useExperiments();
  const { locale: languageCode } = useConfigContext();

  const i18n = useMemo(() => {
    return i18nInit({ locale: locale || languageCode, experiments });
  }, [languageCode]);

  return (
    <React.Suspense fallback={<CenteredLoader />}>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </React.Suspense>
  );
};
