import type { AppBadgeType } from '../apps-badges';
import type { AppsPlans } from './apps-plans';
import type { BillingSource } from '@wix/ambassador-appmarket-v1-app-plans/types';
import {
  SourceType,
  SaleType,
} from '@wix/ambassador-appmarket-v1-app-plans/types';

export { SaleType, SourceType };

export type queryAppsPlans = (payload: {
  appIds: string[];
}) => Promise<AppsPlans>;

export enum DurationUnit {
  UNKNOWN_UNIT = 'UNKNOWN_UNIT',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum CycleType {
  UNKNOWN_TYPE = 'UNKNOWN_TYPE',
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}

export type IAppPlans = {
  taxSettings: TaxDetails;
  currency: CurrencySettings;
  appId: string;
  plans: IAppPlan[];
  badges: AppBadgeType[];
  isExternalBilling: boolean;
};

export type PlanPrice = {
  price?: string;
  discountPrice?: string;
};

export type IAppPlan = {
  meterdBilling?: MeterdBilling;
  yearlyPrice?: PlanPrice;
  monthlyPrice?: PlanPrice;
  oneTimePrice?: PlanPrice;
  vendorId: string;
  discount?: Discount;
  billingSource?: BillingSource;
};

export type CurrencySettings = {
  code: string;
  symbol: string;
};

export enum EDisplayTaxType {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  VAT = 'VAT',
  GST = 'GST',
}

export type TaxDetails = {
  showPriceWithTax: boolean;
  displayTaxType: EDisplayTaxType;
};

export type MeterdBilling = {
  outline?: string;
  baseFee: string;
  discountBaseFee?: string;
};

export enum DiscountType {
  PERCENTAGE = 'PERCENTAGE',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  DISCOUNT_TYPE_UNSPECIFIED = 'DISCOUNT_TYPE_UNSPECIFIED',
}

export type Discount = {
  type: DiscountType;
  amount: string;
  sourceType: SourceType;
  saleType?: SaleType;
  endDate: Date;
};

export const inSale = [SaleType.NOVEMBER_SALE, SaleType.DEVELOPER_SALE];
export const wixSales = [SaleType.NOVEMBER_SALE];
