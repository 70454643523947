import React, { useEffect, useMemo } from 'react';
import { Page } from '@wix/design-system';
import {
  useEssentials,
  useJunkYard,
  useRouterContext,
  useServices,
  useSiteContext,
} from '../../contexts';
import { QueryDecorator } from '../../decorators';
import type { SearchResultsPageData } from './search-results-page-data';
import { AppMarketPage } from '../../enums';
import { getPageViewBiData } from '../../bi-services/events-data-parser';
import {
  appMarketPageView,
  appMarketPageStartLoadedSrc24Evid163,
} from '@wix/bi-logger-app-market-data/v2';
import { SearchResultsSkeleton } from './search-results-skeleton';
import { Header } from './header';
import s from '../pages.scss';
import { withSaleBanner } from '../../components/sale-banner';
import { SearchResultsComponent } from './search-results-component';
import { getSearchResultsPageData } from './search-results-page-data';
import { queryKeyBuilder } from '../../components/apps-section/app-section-helper';
import type { RoutePath, RoutePayloadMap } from '../../models';
import { useComponentLoadReporter } from '../../hooks/component-load-reporter';
import { searchResultsPageId } from './search-results-page-id';

export interface SearchResultsPageComponentProps extends SearchResultsPageData {
  onLoad: () => void;
}

function SearchResultsPageComponent({
  searchTerm,
  appGroup,
  paging,
  onLoad,
}: SearchResultsPageComponentProps) {
  useMemo(() => onLoad(), [searchTerm]);
  const pageName = AppMarketPage.SEARCH_RESULT;
  const { routerData } = useJunkYard();
  const { biLogger } = useEssentials();
  const isAppsFound = paging.total > 0;

  useEffect(() => {
    biLogger.report(
      appMarketPageView(
        getPageViewBiData({
          routerData,
          pageType: pageName,
          pageName: searchTerm,
        }),
      ),
    );
  }, [routerData]);

  return (
    <Page className={s.appMarketPage}>
      <Page.Content>
        <Header {...{ isAppsFound, searchTerm }} />
        <SearchResultsComponent
          searchTerm={searchTerm}
          initData={{ paging, appGroup }}
        />
      </Page.Content>
    </Page>
  );
}

export const SearchResultsPage = withSaleBanner(function ({
  searchTerm,
}: {
  searchTerm: string;
}) {
  const reportLoadFinish = useComponentLoadReporter({
    componentId: searchResultsPageId,
    params: { searchTerm },
    deps: [searchTerm],
  });
  const { biLogger } = useEssentials();
  const { languageCode } = useSiteContext();
  const { route } = useRouterContext();
  const services = useServices();
  const isSale = Boolean(
    (route.payload as RoutePayloadMap[RoutePath.SEARCH_RESULTS]).isSale,
  );

  useEffect(() => {
    biLogger.report(
      appMarketPageStartLoadedSrc24Evid163({
        tag_type: AppMarketPage.SEARCH_RESULT,
      }),
    );
  }, [searchTerm]);

  return (
    <QueryDecorator
      queryFn={() =>
        getSearchResultsPageData({
          searchTerm,
          services,
          isSale,
        })
      }
      queryKey={queryKeyBuilder({
        queryName: 'initial-apps-fetch',
        language: languageCode,
        appsLength: 0,
        isSale,
        queryId: searchTerm,
      })}
    >
      {({ data, isLoading }) =>
        isLoading ? (
          <SearchResultsSkeleton />
        ) : (
          <SearchResultsPageComponent
            searchTerm={searchTerm}
            appGroup={data.appGroup}
            paging={data.paging}
            onLoad={reportLoadFinish}
          />
        )
      }
    </QueryDecorator>
  );
});
