import React from 'react';
import { Box, Divider } from '@wix/design-system';
import type { SupportedAppStatuses } from '@wix/app-market-services';
import { isAppWithBenefits } from '@wix/app-market-services';
import {
  appMarketDemoLinkClicked,
  appMarketDeveloperContactBoxActionClick,
} from '@wix/bi-logger-app-market-data/v2';
import {
  AppOverview,
  AppType,
  CompanyInfo,
  QuickInfo,
  VisibilityDetector,
} from '@wix/app-market-components';
import { useQuickInfoActions } from '../app-page-sections/quick-info/quick-info-actions';
import type { getAppPageDataResponse } from '../app-page-api';
import type { AppPageOptions } from '../types';
import { AppPageCTAs } from '../app-page-ctas';
import { appPageDataHooks } from '../data-hooks';
import { useEssentials, useSiteContext } from '../../../contexts';
import { AppProperties } from '../app-page-sections/app-properties';
import s from '../app-page.scss';

interface AppPageInfoProps
  extends Omit<getAppPageDataResponse, 'pricingPlans' | 'permissions'> {
  options?: AppPageOptions;
  status: SupportedAppStatuses;
  onShowHeader: () => void;
  onHideHeader: () => void;
  onSectionLoad: () => void;
}

export function AppPageInfo({
  app,
  status,
  overview,
  quickInfo,
  managedApp,
  properties,
  companyInfo,
  notification,
  options = {},
  onShowHeader,
  onHideHeader,
  traceableTrader,
  onSectionLoad,
}: AppPageInfoProps) {
  const {
    onTagClick,
    onCompanyClick,
    onReviewsClick,
    onGalleryChange,
    onExpandDescriptionButtonClick,
  } = useQuickInfoActions();
  const { isPremium } = useSiteContext();
  const { i18n, biLogger, experiments } = useEssentials();

  const {
    showCtas = true,
    showRating = true,
    showHeaderLinks = true,
  } = options;

  return (
    <>
      <QuickInfo
        media={quickInfo.media}
        app={app}
        onCompanyClick={(companyName: string, companySlug: string) =>
          onCompanyClick(companyName, companySlug, app.id)
        }
        onReviewsClick={(reviewsAmount: number) =>
          onReviewsClick(app.id, reviewsAmount)
        }
        mainActionButton={
          showCtas && (
            <VisibilityDetector
              onHidden={onShowHeader}
              onVisible={onHideHeader}
            >
              <Box align="center" marginTop={app.isUpgradable ? '6px' : 0}>
                <AppPageCTAs
                  managedApp={managedApp}
                  status={status}
                  app={{
                    id: app.id,
                    instanceId: app.instanceId,
                    name: app.name,
                    isUpgradable: app.isUpgradable,
                    isAppWithBenefits: isAppWithBenefits(app.appBadges),
                    isInstalled: app.isInstalled,
                    isBuiltIn: Boolean(app.installation.isBuiltIn),
                    appBadges: app.appBadges,
                    notification,
                    gTag: app.gTag,
                  }}
                  dataHook={appPageDataHooks.APP_PAGE_CTA}
                  size={app.isUpgradable ? 'small' : 'large'}
                />
              </Box>
            </VisibilityDetector>
          )
        }
        onGalleryChange={(oldIndex: number, newIndex: number) =>
          onGalleryChange(oldIndex, newIndex, app.id)
        }
        i18n={i18n}
        experiments={experiments}
        isPremiumSite={isPremium}
        options={{ showRating, isCompanyClickable: status !== 'APPROVED' }}
      />
      <Box margin="36px 0px">
        <Divider />
      </Box>
      <VisibilityDetector onFirstVisible={onSectionLoad}>
        <Box className={s.mainContent} gap="24px">
          <Box flex={1}>
            {Boolean(overview.description) &&
              Boolean(overview.benefits?.length) && (
                <AppOverview
                  appName={app.name}
                  description={overview.description}
                  benefits={overview.benefits}
                  demoUrl={overview.demoUrl}
                  onClickDemoUrl={() => {
                    biLogger.report(
                      appMarketDemoLinkClicked({
                        app_id: app.id,
                        demo_link: overview.demoUrl,
                      }),
                    );
                  }}
                  onExpandToggleClick={() =>
                    onExpandDescriptionButtonClick?.(app.id)
                  }
                  i18n={i18n}
                />
              )}
          </Box>
          <Box
            className={s.propertiesCompanyContainer}
            direction="vertical"
            gap="24px"
          >
            <AppProperties
              appId={app.id}
              appName={app.name}
              isWixApp={app.type === AppType.WIX_APP}
              geoAvailability={properties.geoAvailability}
              appCollections={properties.appCollections}
              supportedLanguages={properties.supportedLanguages}
              isAppContentTranslatable={properties.isAppContentTranslatable}
              appDependencies={properties.appDependencies}
              subCategories={showHeaderLinks ? quickInfo.subCategories : []}
              onTagClick={(
                categorySlug: string,
                subCategory: string,
                label: 'category' | 'sub_category',
                location: 'side_category_page',
              ) =>
                onTagClick(categorySlug, subCategory, app.id, label, location)
              }
            />
            <CompanyInfo
              traceableTrader={traceableTrader}
              appInfo={{
                name: companyInfo.name,
                imageURL: companyInfo.image,
              }}
              website={{
                href: companyInfo.websiteUrl,
                onClick: () => {
                  biLogger.report(
                    appMarketDeveloperContactBoxActionClick({
                      app_id: app.id,
                      actionName: 'visit_website',
                    }),
                  );
                },
              }}
              contactSupport={{
                href: companyInfo.contactUs,
                onClick: () => {
                  biLogger.report(
                    appMarketDeveloperContactBoxActionClick({
                      app_id: app.id,
                      actionName: 'contact_support',
                    }),
                  );
                },
              }}
              privacyPolicy={{
                href: companyInfo.privacyPolicyUrl,
                onClick: () => {
                  biLogger.report(
                    appMarketDeveloperContactBoxActionClick({
                      app_id: app.id,
                      actionName: 'privacy',
                    }),
                  );
                },
              }}
              i18n={i18n}
            />
          </Box>
        </Box>
      </VisibilityDetector>
    </>
  );
}
