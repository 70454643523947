import React, { useEffect, useMemo, useState } from 'react';
import { Box, Divider } from '@wix/design-system';
import { CenteredLoader } from '../../components/centered-loader';
import { QueryDecorator } from '../../decorators';
import {
  appMarketAppPageScrollPage,
  appMarketPageStartLoadedSrc24Evid163,
  appMarketPageView,
} from '@wix/bi-logger-app-market-data/v2';
import type { getAppPageDataResponse } from './app-page-api';
import { getAppPageDataById, getAppPageDataBySlug } from './app-page-api';
import {
  isAppWithBenefits,
  type SupportedAppStatuses,
} from '@wix/app-market-services';
import {
  useEssentials,
  useJunkYard,
  useRouterContext,
  useServices,
  useSiteContext,
} from '../../contexts';
import { AppNotification } from './app-page-sections/notifications/app-notification';
import { ReviewsSection } from './app-page-sections/reviews/reviews-section';
import { AppPageSticky, VisibilityDetector } from '@wix/app-market-components';
import { AppPageCTAs } from './app-page-ctas';
import { useQuickInfoActions } from './app-page-sections/quick-info/quick-info-actions';
import { AppPricingPlansSection } from './app-page-sections/pricing-plans';
import { getAppPageViewBiData } from '../../bi-services/events-data-parser';
import { AppMarketPage } from '../../enums';
import type { getReviewsSectionResponse } from './app-page-sections/reviews/reviews-api';
import { RecommendedForYouSection } from './app-page-sections/recommended-for-you';
import s from './app-page.scss';
import { BackButton } from '../../components/back-button';
import type { AppPageOptions } from './types';
import { appPageDataHooks } from './data-hooks';
import { useQueryClient } from '@tanstack/react-query';
import { AppPageSEO } from './app-page-seo';
import { withSaleBanner } from '../../components/sale-banner';
import { useComponentLoadReporter } from '../../hooks/component-load-reporter';
import { appPageId } from './app-page-id';
import { initGoogleAnalytics } from '../../methods/init-google-analytics';
import type { RoutePath, RoutePayloadMap } from '../../models';
import { getPageViewAnalyticsParams } from '../../bi-services/utils';
import { AppPageInfo, LeanAppPageInfo } from './app-page-info';

export interface AppPageProps {
  id?: string;
  slug?: string;
  status?: SupportedAppStatuses;
  options?: AppPageOptions;
}

export interface AppPageComponentProps extends getAppPageDataResponse {
  onChange: (reviews: getAppPageDataResponse['reviews']) => void;
  onLoad: () => void;
  status?: SupportedAppStatuses;
  options?: AppPageOptions;
}

export function AppPageComponent({
  app,
  managedApp,
  notification,
  reviews,
  overview,
  quickInfo,
  properties,
  companyInfo,
  onChange,
  onLoad,
  pricingPlans,
  status = 'PUBLISHED',
  options = {},
  traceableTrader,
  permissions,
}: AppPageComponentProps) {
  useMemo(() => onLoad(), [app.id]);
  const { onCompanyClick, onBack: onBackAction } = useQuickInfoActions();
  const { i18n, biLogger, experiments, googleAnalytics } = useEssentials();
  const { metaSiteId, isPremium } = useSiteContext();
  const { router, route } = useRouterContext();
  const { routerData, goBackToReferrer } = useJunkYard();
  const [shouldShowHeader, setShouldShowHeader] = useState<boolean>(false);
  const onBack = router.hasHistory() ? () => onBackAction(app.id) : undefined;
  const {
    showRecommendedForYou = true,
    showHeaderLinks = true,
    stickyHeaderOptions,
    pricingOptions,
    showReviews = true,
    showStickyHeader = true,
    showPricingPlans = true,
  } = options;

  const isSkinLean = options?.skin === 'lean';

  useEffect(() => {
    onAppPageLoad({ app, reviews, properties });
  }, [app, routerData]);

  useEffect(() => {
    const gaInstance = initGoogleAnalytics(googleAnalytics, app.gTag);
    const handlePageLoadAnalytics = async () => {
      gaInstance?.reportPageView({
        params: getPageViewAnalyticsParams(
          route.payload as RoutePayloadMap[RoutePath.APP_PAGE],
        ),
      });
    };
    handlePageLoadAnalytics();

    return () => {
      const isGoogleAnalyticsEnabled = experiments.enabled(
        'specs.app-market.enableGoogleAnalytics',
      );
      if (isGoogleAnalyticsEnabled) {
        googleAnalytics.reset();
      }
    };
  }, [app]);

  const onSectionFirstVisible = ({
    sectionName,
    sectionIndex,
  }: {
    sectionName: string;
    sectionIndex: number;
  }) => {
    biLogger.report(
      appMarketAppPageScrollPage({
        app_id: app.id,
        page_part: sectionName,
        page_part_index: sectionIndex,
        tag_name: 'app_page',
      }),
    );
  };

  const onAppPageLoad = (props: {
    app: getAppPageDataResponse['app'];
    reviews: getAppPageDataResponse['reviews'];
    properties: getAppPageDataResponse['properties'];
  }) => {
    const searchTerm = location.search.split('referralTag=')?.[1];
    biLogger.report(
      appMarketPageView(
        getAppPageViewBiData({
          routerData,
          pageType: AppMarketPage.APP_PAGE,
          appId: props.app.id,
          appSlug: props.app.slug,
          appReviewCount: props.reviews.reviewsSummary.totalReviews,
          appReviewScore: props.reviews.reviewsSummary.averageRating,
          numberOfDependedApps: props.properties.appDependencies.length,
          searchTerm,
          appBadges: props.app.appBadges,
          referrerNameForBI: goBackToReferrer?.referrerNameForBI,
        }),
      ),
    );
  };

  return (
    <div data-hook={appPageDataHooks.APP_PAGE_COMPONENT}>
      {showStickyHeader && (
        <AppPageSticky
          isVisible={shouldShowHeader}
          icon={app.icon}
          company={companyInfo}
          installation={app.installation}
          pricing={app.pricing}
          appBadges={app.appBadges}
          onBack={onBack}
          mainActionButton={
            <AppPageCTAs
              managedApp={managedApp}
              status={status}
              app={{
                id: app.id,
                instanceId: app.instanceId,
                name: app.name,
                isUpgradable: app.isUpgradable,
                isAppWithBenefits: isAppWithBenefits(app.appBadges),
                isInstalled: app.isInstalled,
                isBuiltIn: Boolean(app.installation.isBuiltIn),
                appBadges: app.appBadges,
                notification,
                gTag: app.gTag,
              }}
              size="small"
              dataHook={appPageDataHooks.APP_STICKY_HEADER_CTA}
              reverseOrder
            />
          }
          onCompanyClick={(companyName: string, companySlug: string) =>
            onCompanyClick(companyName, companySlug, app.id)
          }
          name={app.name}
          isAppUpgradable={app.isUpgradable}
          i18n={i18n}
          experiments={experiments}
          isPremiumSite={isPremium}
          options={stickyHeaderOptions}
        />
      )}
      <div className={`${s.appPage} ${isSkinLean ? s.lean : ''}`}>
        {showHeaderLinks && (
          <Box marginTop="18px">
            {onBack && (
              <Box dataHook={appPageDataHooks.BACK_BUTTON}>
                <BackButton
                  size="tiny"
                  weight="thin"
                  skin="dark"
                  onBack={onBack}
                />
              </Box>
            )}
          </Box>
        )}

        {notification ? (
          <Box margin={`12px ${isSkinLean ? '24px' : '0'}`}>
            <AppNotification notification={notification} />
          </Box>
        ) : null}

        {isSkinLean ? (
          <LeanAppPageInfo
            app={app}
            overview={overview}
            quickInfo={quickInfo}
            permissions={permissions}
          />
        ) : (
          <AppPageInfo
            app={app}
            status={status}
            options={options}
            reviews={reviews}
            overview={overview}
            quickInfo={quickInfo}
            properties={properties}
            companyInfo={companyInfo}
            notification={notification}
            traceableTrader={traceableTrader}
            onSectionLoad={() =>
              onSectionFirstVisible({
                sectionName: 'SolutionOverview',
                sectionIndex: 1,
              })
            }
            onShowHeader={() => setShouldShowHeader(true)}
            onHideHeader={() => setShouldShowHeader(false)}
          />
        )}

        {showReviews && (
          <>
            <Box margin="36px 0px">
              <Divider dataHook="reviews-top-divider" />
            </Box>
            <VisibilityDetector
              onFirstVisible={() =>
                onSectionFirstVisible({
                  sectionName: 'SolutionReviews',
                  sectionIndex: 2,
                })
              }
            >
              <ReviewsSection
                app={app}
                company={companyInfo}
                reviews={reviews}
                onChange={onChange}
              />
            </VisibilityDetector>
          </>
        )}

        {showPricingPlans && pricingPlans && !pricingPlans.isFreeApp && (
          <>
            <Box margin="36px 0px">
              <Divider />
            </Box>

            <VisibilityDetector
              onFirstVisible={() =>
                onSectionFirstVisible({
                  sectionName: 'SolutionPricingPlans',
                  sectionIndex: 3,
                })
              }
            >
              <AppPricingPlansSection
                {...{ ...pricingPlans, options: pricingOptions }}
              />
            </VisibilityDetector>
          </>
        )}

        {showRecommendedForYou && metaSiteId && (
          <>
            <Box margin="36px 0px">
              <Divider />
            </Box>
            <VisibilityDetector
              onFirstVisible={() =>
                onSectionFirstVisible({
                  sectionName: 'SolutionRelatedApps',
                  sectionIndex: 4,
                })
              }
            >
              <RecommendedForYouSection appId={app.id} />
            </VisibilityDetector>
          </>
        )}
      </div>
    </div>
  );
}

export const AppPage = withSaleBanner(function ({
  id,
  slug,
  status = 'PUBLISHED',
  options,
}: AppPageProps) {
  const siteContext = useSiteContext();
  const languageCode = siteContext.languageCode;
  const queryKey = `${appPageId}-${id || slug}-${languageCode}-${status}`;

  const reportLoadFinish = useComponentLoadReporter({
    componentId: appPageId,
    params: { appId: id, slug },
    deps: [queryKey],
  });
  const { biLogger, experiments } = useEssentials();
  const queryClient = useQueryClient();
  const services = useServices();
  const [reviews, setReviews] = useState<
    getReviewsSectionResponse | undefined
  >();

  useEffect(() => {
    biLogger.report(
      appMarketPageStartLoadedSrc24Evid163({
        tag_type: AppMarketPage.APP_PAGE,
      }),
    );
  }, [slug]);

  return (
    <QueryDecorator
      queryFn={() =>
        id
          ? getAppPageDataById({
              services,
              id,
              languageCode,
              siteContext,
              status,
              experiments,
            })
          : slug
          ? getAppPageDataBySlug({
              services,
              slug,
              languageCode,
              status,
              siteContext,
              experiments,
            })
          : Promise.reject(new Error('No id or slug provided'))
      }
      queryKey={queryKey}
    >
      {({ data, isLoading }) => {
        if (isLoading) {
          return <CenteredLoader />;
        }

        return (
          <>
            {!siteContext.metaSiteId && (
              <AppPageSEO
                description={data.app.shortDescription}
                metaTagsAggregator={siteContext.metaTagsAggregator ?? []}
                appName={data.app.name}
                reviewsSummary={data.reviews.reviewsSummary}
                icon={data.app.icon}
              />
            )}
            <AppPageComponent
              {...data}
              options={options}
              reviews={reviews ?? data.reviews}
              onChange={(newReviews: getReviewsSectionResponse) => {
                setReviews(newReviews);
                queryClient.invalidateQueries([queryKey]);
              }}
              onLoad={reportLoadFinish}
              status={status}
            />
          </>
        );
      }}
    </QueryDecorator>
  );
});
