export interface ITraceableTrader {
  phone: string;
  email: string;
  address: string;
  name: string;
}

export enum TraderType {
  UNKNOWN_TRADER_TYPE = 'UNKNOWN_TRADER_TYPE',
  COMPANY = 'COMPANY',
  FREELANCER = 'FREELANCER',
}

export enum ValidationStatus {
  UNKNOWN_VALIDATION_STATUS = 'UNKNOWN_VALIDATION_STATUS',
  VALID = 'VALID',
  NOT_VALID = 'NOT_VALID',
  PENDING_VALIDATION = 'PENDING_VALIDATION',
  OPTED_OUT = 'OPTED_OUT',
}
