import s from '../pages.scss';
import React, { useEffect } from 'react';
import { Page } from '@wix/design-system';
import { useEssentials, useJunkYard } from '../../contexts';
import { AppMarketPage } from '../../enums';
import {
  appMarketPageStartLoadedSrc24Evid163,
  appMarketPageView,
} from '@wix/bi-logger-app-market-data/v2';
import { withSaleBanner } from '../../components/sale-banner';
import { CollectionAppsSection } from './collection-apps-section';
import { CollectionHeader } from './collection-header';
import { getPageViewBiData } from '../../bi-services/events-data-parser';

export const CollectionPage = withSaleBanner(function ({
  slug,
}: {
  slug: string;
}) {
  const { biLogger } = useEssentials();
  const { routerData } = useJunkYard();

  useEffect(() => {
    biLogger.report(
      appMarketPageView(
        getPageViewBiData({
          routerData,
          pageType: AppMarketPage.COLLECTION,
          pageName: slug,
        }),
      ),
    );
  }, [routerData]);

  useEffect(() => {
    biLogger.report(
      appMarketPageStartLoadedSrc24Evid163({
        tag_type: AppMarketPage.COLLECTION,
      }),
    );
  }, [slug]);

  return (
    <Page className={`${s.appMarketPage} ${s.appMarketPageBackgroundColor}`}>
      <Page.Content>
        <CollectionHeader slug={slug} />
        <CollectionAppsSection slug={slug} />
      </Page.Content>
    </Page>
  );
});
