import type {
  TraceableTrader as TraceableTraderType,
  Address as AddressType,
} from '@wix/ambassador-partners-traceability-v1-traceable-trader/types';
import type { ITraceableTrader } from './types';
import { ValidationStatus, TraderType } from './types';

export class TraceableTrader {
  constructor(private readonly traceableTrader: TraceableTraderType) {}

  get phone(): string {
    return this.traceableTrader.businessPhone ?? '';
  }

  get email(): string {
    return this.traceableTrader.businessEmail ?? '';
  }

  get address(): string {
    const { businessAddress = {} as AddressType } = this.traceableTrader;
    const {
      countryFullname = '',
      subdivision = '',
      city = '',
      streetAddress = {},
    } = businessAddress;
    const { formattedAddressLine = '' } = streetAddress;
    return `${countryFullname}, ${subdivision}, ${city} ${formattedAddressLine}`;
  }

  private get traderType(): TraderType {
    return this.traceableTrader.traderType === TraderType.COMPANY
      ? TraderType.COMPANY
      : this.traceableTrader.traderType === TraderType.FREELANCER
      ? TraderType.FREELANCER
      : TraderType.UNKNOWN_TRADER_TYPE;
  }

  get name(): string {
    switch (this.traderType) {
      case TraderType.COMPANY:
        return this.traceableTrader.companyOptions?.businessName ?? '';
      case TraderType.FREELANCER:
        return `${this.traceableTrader.freelancerOptions?.firstName ?? ''} ${
          this.traceableTrader.freelancerOptions?.lastName ?? ''
        }`;
      default:
        return '';
    }
  }

  toJSON(): ITraceableTrader | null {
    if (this.traceableTrader.validationStatus !== ValidationStatus.VALID) {
      return null;
    } else {
      return {
        phone: this.phone,
        email: this.email,
        address: this.address,
        name: this.name,
      };
    }
  }
}
