import React, { useEffect } from 'react';
import { Box, Page } from '@wix/design-system';
import { HomePageHeader } from './home-page-header';
import s from '../pages.scss';
import { SaleBanner } from '../../components/sale-banner';
import { useJunkYard, useEssentials } from '../../contexts';
import { appMarketPageView } from '@wix/bi-logger-app-market-data/v2';
import { getPageViewBiData } from '../../bi-services/events-data-parser';
import { AppMarketPage } from '../../enums';
import { HomePageSections } from './home-page-sections';
import { useComponentLoadReporter } from '../../hooks/component-load-reporter';
import { homePageId } from './home-page-id';

export function HomePage() {
  const reportLoadFinish = useComponentLoadReporter({
    componentId: homePageId,
  });
  const page = AppMarketPage.HOMEPAGE;
  const { routerData } = useJunkYard();
  const { biLogger, experiments } = useEssentials();

  useEffect(() => {
    biLogger.report(
      appMarketPageView(
        getPageViewBiData({
          routerData,
          pageType: page,
          pageName: page,
        }),
      ),
    );
  }, [routerData]);

  return (
    <Page
      className={`${s.appMarketPage} ${s.appMarketPageBackgroundColor}`}
      dataHook={homePageId}
    >
      <Page.Content>
        <HomePageHeader />
        {experiments.enabled('specs.app-market.Sale') ? (
          <Box direction="vertical" marginBottom="30px">
            <SaleBanner layout="narrow" />
          </Box>
        ) : null}
        <HomePageSections onLoad={reportLoadFinish} />
      </Page.Content>
    </Page>
  );
}
