import React, { useState } from 'react';
import { Box, FloatingHelper, Text, TextButton } from '@wix/design-system';
import { Trans } from '@wix/wix-i18n-config';
import type { ITraceableTrader } from '@wix/app-market-services';
import { useEssentials } from '../../contexts/essentials-context';
import { TraceableTraderPopoverContent } from './traceable-trader-popover-content';

export function TraceableTrader(traceableTrader: ITraceableTrader) {
  const { i18n, t } = useEssentials();

  return (
    <Box
      marginTop={1}
      dataHook="traceable-trader-disclaimer"
      width="100%"
      direction="vertical"
    >
      <Text secondary size="tiny" lineHeight="tiny">
        <Trans
          t={t}
          i18n={i18n}
          i18nKey="app-page.traceable-trader-line"
          values={{ companyName: traceableTrader.name }}
          components={{
            linkbutton: <LinkButton traceableTrader={traceableTrader} />,
          }}
        />
      </Text>
    </Box>
  );
}

function LinkButton({
  children,
  traceableTrader,
}: {
  children?: React.ReactNode;
  traceableTrader: ITraceableTrader;
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <FloatingHelper
      dataHook="traceable-trader-floating-helper"
      zIndex={9999}
      opened={isPopoverOpen}
      onClose={() => setIsPopoverOpen(false)}
      appearance="light"
      target={
        <TextButton
          dataHook="traceable-trader-disclaimer-link"
          size="tiny"
          underline="always"
          skin="dark"
          onClick={() => {
            setIsPopoverOpen(!isPopoverOpen);
          }}
        >
          {children}
        </TextButton>
      }
      content={
        <FloatingHelper.Content
          title="EU Trader Disclaimer"
          body={<TraceableTraderPopoverContent {...traceableTrader} />}
        />
      }
      placement="right"
    />
  );
}
