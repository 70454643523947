import type { AppBoxData } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import type { AppDataType } from './types';
import { AppData } from './appData';

export class AppsData {
  constructor(private readonly appsData: AppBoxData[]) {}

  findByAppId(appId: string): AppDataType {
    const appData =
      this.appsData.find((data) => data && data.id === appId) ?? {};
    return new AppData(appData).toJSON();
  }

  toJSON(): AppDataType[] {
    return this.appsData.map((appData) => new AppData(appData).toJSON());
  }
}
