import {
  manageAppsUpgradeSite,
  appMarketClickOnFreeTrialCta,
} from '@wix/bi-logger-app-market-data/v2';
import { useEssentials } from '../../../../contexts';
import type { AppBadge } from '@wix/app-market-services';
import {
  getManageAppsUpgradeSite,
  getAppMarketClickOnFreeTrialCta,
} from '../../../../bi-services/events-data-parser';

export function useUpgradeButtonBiEvents({
  app,
  origin,
  showRedeemOfferButton,
}: {
  app: {
    id: string;
    instanceId?: string;
    appBadges: AppBadge[];
  };
  origin: string;
  showRedeemOfferButton: boolean;
}) {
  const { biLogger } = useEssentials();

  return {
    reportUpgradeClick: () => {
      biLogger.report(
        manageAppsUpgradeSite(
          getManageAppsUpgradeSite({
            appId: app.id,
            appInstanceId: app.instanceId,
            upgrade_origin: origin,
            cta_type: showRedeemOfferButton ? 'redeem' : 'upgrade',
            appBadges: app.appBadges,
          }),
        ),
      );
    },

    reportFreeTrialClick: () => {
      biLogger.report(
        appMarketClickOnFreeTrialCta(
          getAppMarketClickOnFreeTrialCta({
            appId: app.id,
            appInstanceId: app.instanceId,
            origin,
            appBadges: app.appBadges,
          }),
        ),
      );
    },
  };
}
