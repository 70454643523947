import { stringify } from 'query-string';
import type { QueryMap } from '../query';
import { RoutePath } from './routes';
import type { IRoute, RoutePayloadMap } from './routes';
import type { IRoute as OldRoute } from '../junk-yard';
import { Path } from '../junk-yard';
import { paramsRoute } from './params-route';
import { deepCompare } from '../../methods/deep-compare';
import { homePageId } from '../../pages/home-page/home-page-id';
import { categoryPageId } from '../../pages/category/category-page-id';
import { searchResultsPageId } from '../../pages/search-results/search-results-page-id';
import { appPageId } from '../../pages/app-page/app-page-id';

export class Route {
  private readonly path: RoutePath;
  private readonly payload: RoutePayloadMap[RoutePath];
  private readonly baseURL: string | undefined;

  constructor({
    path,
    payload,
    baseURL,
  }: {
    path: RoutePath;
    payload: RoutePayloadMap[RoutePath];
    baseURL: string | undefined;
  }) {
    this.path = path;
    this.payload = payload;
    this.baseURL = baseURL;
  }

  static toRoute = ({
    path,
    payload,
    slug,
    subSlug,
    baseURL,
  }: {
    path: string;
    payload: Partial<QueryMap>;
    slug?: string;
    subSlug?: string;
    baseURL: string | undefined;
  }): Route => {
    if (payload.appMarketParams || payload.openAppDefId) {
      return paramsRoute({ payload, baseURL, slug });
    }
    switch (path) {
      case Path.COLLECTION:
        return new Route({
          path: RoutePath.COLLECTION,
          payload: {
            ...payload,
            slug: slug!,
            ...(payload.isSale ? { isSale: payload.isSale === 'true' } : {}),
          },
          baseURL,
        });
      case Path.CATEGORY:
        if (subSlug) {
          return new Route({
            path: RoutePath.SUB_CATEGORY,
            payload: {
              ...payload,
              parentSlug: slug!,
              slug: subSlug,
              subCat: subSlug,
              ...(payload.isSale ? { isSale: payload.isSale === 'true' } : {}),
            },
            baseURL,
          });
        }
        return new Route({
          path: RoutePath.CATEGORY,
          payload: { ...payload, slug: slug! },
          baseURL,
        });
      case Path.WEB_SOLUTION:
        return new Route({
          path: RoutePath.APP_PAGE,
          payload: { ...payload, appId: slug!, slug },
          baseURL,
        });
      case Path.UNLISTED_APP:
        return new Route({
          path: RoutePath.UNLISTED_APP,
          payload: { ...payload, shareUrlId: slug!, hideMenu: true },
          baseURL,
        });
      case Path.SEARCH:
        return new Route({
          path: RoutePath.SEARCH_RESULTS,
          payload: {
            ...payload,
            ...(payload.isSale ? { isSale: payload.isSale === 'true' } : {}),
          },
          baseURL,
        });
      case Path.DEVELOPER:
        return new Route({
          path: RoutePath.DEVELOPER,
          payload: { slug },
          baseURL,
        });
      case Path.HOME:
        return new Route({
          path: RoutePath.HOME,
          payload,
          baseURL,
        });
      case Path.MANAGE_APPS:
        return new Route({
          path: RoutePath.MANAGE_APPS,
          payload,
          baseURL,
        });
      case Path.FINISH_SETUP:
        return new Route({
          path: RoutePath.FINISH_SETUP,
          payload,
          baseURL,
        });
      case Path.OPEN_APP_WITH_PARAMS:
        return new Route({
          path: RoutePath.OPEN_APP_WITH_PARAMS,
          payload,
          baseURL,
        });
      case Path.SHARE:
        return new Route({
          path: RoutePath.SHARE,
          payload,
          baseURL,
        });
      case Path.FINISH_UPDATE:
        return new Route({
          path: RoutePath.FINISH_UPDATE,
          payload,
          baseURL,
        });
      default:
        if (path && !slug) {
          return new Route({
            path: RoutePath.APP_PAGE,
            payload: { ...payload, slug: path, appId: path },
            baseURL,
          });
        }

        return new Route({
          path: RoutePath.UNSUPPORTED,
          payload: undefined,
          baseURL,
        });
    }
  };

  toPathname(): string {
    switch (this.path) {
      case RoutePath.COLLECTION: {
        const { slug } = this.payload as RoutePayloadMap[RoutePath.COLLECTION];
        return `/collection/${slug}`;
      }
      case RoutePath.CATEGORY: {
        const { slug } = this.payload as RoutePayloadMap[RoutePath.CATEGORY];
        return `/category/${slug}`;
      }
      case RoutePath.SUB_CATEGORY: {
        const { slug, parentSlug } = this
          .payload as RoutePayloadMap[RoutePath.SUB_CATEGORY];
        return `/category/${parentSlug}/${slug}`;
      }
      case RoutePath.APP_PAGE: {
        const { slug } = this.payload as RoutePayloadMap[RoutePath.APP_PAGE];
        return `/web-solution/${slug}`;
      }
      case RoutePath.UNLISTED_APP: {
        const { shareUrlId } = this
          .payload as RoutePayloadMap[RoutePath.UNLISTED_APP];
        return `/install/${shareUrlId}`;
      }
      case RoutePath.SEARCH_RESULTS:
        return `/search-result`;
      case RoutePath.DEVELOPER:
        const { slug } = this.payload as RoutePayloadMap[RoutePath.DEVELOPER];
        return `/developer/${slug}`;
      default:
        return `/`;
    }
  }

  toSearch(): string {
    const payload = (this.payload as Partial<QueryMap>) || {};

    const search = stringify({
      query: payload.query,
      referral: payload.referral,
      referralInfo: payload.referralInfo,
      appIndex: payload.appIndex,
      referralTag: payload.referralTag,
      referralSectionName: payload.referralSectionName,
      section: payload.section,
      searchLocation: payload.searchLocation,
      utm_id: payload.utm_id,
      subCat: payload.subCat,
      isSale: payload.isSale,
    });

    return search;
  }

  toHref(): string {
    const baseURL = this.baseURL || '';
    const pathName = this.toPathname();
    const search = this.toSearch();

    if (search) {
      return `${baseURL}${pathName}?${search}`;
    }
    return `${baseURL}${pathName}`;
  }

  toOldRoute(): OldRoute {
    const baseOldRoute: OldRoute = {
      ...(this.payload?.referral ? { referral: this.payload?.referral } : {}),
      ...(this.payload?.referralSectionName
        ? { referralSectionName: this.payload?.referralSectionName }
        : {}),
      ...(this.payload?.hideMenu || this.payload?.hideMenu === false
        ? { hideMenu: this.payload?.hideMenu }
        : {}),
      ...(this.payload?.version ? { version: this.payload.version } : {}),
    };

    switch (this.path) {
      case RoutePath.COLLECTION:
        return {
          path: Path.COLLECTION,
          slug: (this.payload as RoutePayloadMap[RoutePath.COLLECTION]).slug,
          ...(baseOldRoute ? baseOldRoute : {}),
        };
      case RoutePath.APP_PAGE:
        const payload = this.payload as RoutePayloadMap[RoutePath.APP_PAGE];
        return {
          path: Path.WEB_SOLUTION,
          slug: payload.slug,
          ...(payload.appIndex ? { appIndex: Number(payload.appIndex) } : {}),
          ...(baseOldRoute ? baseOldRoute : {}),
        };
      case RoutePath.CATEGORY:
        return {
          path: Path.CATEGORY,
          slug: (this.payload as RoutePayloadMap[RoutePath.CATEGORY]).slug,
          ...(baseOldRoute ? baseOldRoute : {}),
        };
      case RoutePath.SUB_CATEGORY:
        return {
          path: Path.CATEGORY,
          slug: (this.payload as RoutePayloadMap[RoutePath.SUB_CATEGORY])
            .parentSlug,
          subCategories: [
            (this.payload as RoutePayloadMap[RoutePath.SUB_CATEGORY]).slug,
          ],
          ...(baseOldRoute ? baseOldRoute : {}),
        };
      case RoutePath.FINISH_SETUP:
        return {
          path: Path.FINISH_SETUP,
          slug: (this.payload as RoutePayloadMap[RoutePath.FINISH_SETUP]).appId,
          version: (this.payload as RoutePayloadMap[RoutePath.FINISH_SETUP])
            .version,
          ...(baseOldRoute ? baseOldRoute : {}),
        };

      case RoutePath.SEARCH_RESULTS:
        return {
          path: Path.SEARCH,
          query: (this.payload as RoutePayloadMap[RoutePath.SEARCH_RESULTS])
            .query,
          ...(baseOldRoute ? baseOldRoute : {}),
        };
      case RoutePath.DEVELOPER:
        return {
          path: Path.DEVELOPER,
          slug: (this.payload as RoutePayloadMap[RoutePath.DEVELOPER]).slug,
          ...(baseOldRoute ? baseOldRoute : {}),
        };
      case RoutePath.MANAGE_APPS:
        return {
          path: Path.MANAGE_APPS,
          ...(baseOldRoute ? baseOldRoute : {}),
        };
      case RoutePath.HOME:
        return {
          path: Path.HOME,
          ...(baseOldRoute ? baseOldRoute : {}),
        };
      default:
        return {};
    }
  }

  equals(route: IRoute): boolean {
    const excludeFields = ['hideMenu', 'appId', 'appIndex', 'isSale'];
    const excludePath = [
      RoutePath.SHARE,
      RoutePath.FINISH_UPDATE,
      RoutePath.FINISH_SETUP,
      RoutePath.OPEN_APP_WITH_PARAMS,
    ];
    return (
      this.path === route.path &&
      (excludePath.includes(this.path) ||
        deepCompare(this.payload, route.payload, excludeFields))
    );
  }

  toJSON(): IRoute {
    return { path: this.path, payload: this.payload } as IRoute;
  }

  toComponentId(): string {
    switch (this.path) {
      case RoutePath.HOME:
        return homePageId;
      case RoutePath.COLLECTION:
        return 'collection-page';
      case RoutePath.CATEGORY:
        return categoryPageId;
      case RoutePath.SUB_CATEGORY:
        return 'sub-category-page';
      case RoutePath.SEARCH_RESULTS:
        return searchResultsPageId;
      case RoutePath.APP_PAGE:
        return appPageId;
      case RoutePath.SHARE:
        return 'share-page';
      case RoutePath.DEVELOPER:
        return 'developer-page';
      case RoutePath.FINISH_SETUP:
        return 'finish-setup-page';
      case RoutePath.FINISH_UPDATE:
        return 'finish-update-page';
      case RoutePath.MANAGE_APPS:
        return 'manage-apps-page';
      case RoutePath.OPEN_APP_WITH_PARAMS:
        return 'open-app-with-params-page';
      case RoutePath.UNSUPPORTED:
        return 'unsupported-page';
      default:
        return '';
    }
  }
}
