import React from 'react';
import { Box, Divider, Heading, TextButton } from '@wix/design-system';
import { ExpandedText } from '../../components/expanded-text';
import type { BenefitType } from '../../components/benefit';
import { Benefit } from '../../components/benefit';
import { SiteSearch } from '@wix/wix-ui-icons-common';
import {
  useEssentials,
  withEssentialsContext,
} from '../../contexts/essentials-context';
import type { ExternalComponentProps } from '../types';

export interface OverviewProps extends ExternalComponentProps {
  appName: string;
  description: string;
  benefits?: BenefitType[];
  demoUrl?: string | undefined;
  onClickDemoUrl?: () => void;
  onExpandToggleClick?(): void;
}

const AppOverviewComponent = ({
  appName,
  description,
  benefits,
  demoUrl,
  onClickDemoUrl,
  onExpandToggleClick,
}: OverviewProps) => {
  const { t } = useEssentials();
  if (!appName || appName === '') {
    return <></>;
  }
  return (
    <Box direction="vertical">
      <Heading size="large" dataHook="overview-name">
        {t('app.page.overview.title', {
          name: appName,
          interpolation: { escapeValue: false },
        })}
      </Heading>
      <Box marginTop="24px" direction="vertical" align="left">
        {benefits?.map(
          (benefit, index) =>
            benefit.title &&
            benefit.title !== '' && (
              <Benefit key={index} title={benefit.title} />
            ),
        )}
      </Box>
      <ExpandedText
        text={description}
        onExpandToggleClick={onExpandToggleClick}
      />
      {demoUrl && (
        <Box direction="vertical" marginTop="24px" gap="12px">
          <Divider />
          <TextButton
            prefixIcon={<SiteSearch />}
            as="a"
            href={demoUrl}
            onClick={onClickDemoUrl}
            target="_blank"
          >
            {t('app.page.overview.demoLink')}
          </TextButton>
        </Box>
      )}
    </Box>
  );
};

export const AppOverview = withEssentialsContext(AppOverviewComponent);
