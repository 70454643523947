import React from 'react';
import { DeveloperSaleBadge } from './dev-sale-badge';
import { WixSaleBadge } from './wix-sale-badge';
import { BetaBadge } from './beta-badge';
import { WixChoiceBadge } from './wix-choice-badge';
import { BundleBadge } from './bundle-badge';
import { AppBadgesType, BundleAppType } from '@wix/app-market-services';
import type { AppBadge } from '@wix/app-market-services';
import { useEssentials } from '../../contexts/essentials-context';
import { AppBenefitsBadge } from './app-benefits-badge';

interface AppBadgesProps {
  appBadges: AppBadge[];
  appName: string;
  showBorder?: boolean;
  isInstalled?: boolean;
  size?: 'tiny' | 'small' | 'medium';
  wixSaleSize?: 'small' | 'big';
}

export function AppBadges({
  appBadges,
  appName,
  showBorder = false,
  isInstalled,
  size = 'small',
  wixSaleSize = 'big',
}: AppBadgesProps) {
  const { experiments } = useEssentials();
  const showNovemberSaleBadge =
    experiments?.enabled('specs.app-market.Sale') ?? false;
  const showDeveloperSaleBadge =
    experiments?.enabled('specs.app-market.DeveloperSaleBadge') ?? false;
  const replaceBundleBadge = Boolean(
    experiments?.enabled('specs.app-market.MoveBundlesToBenefits'),
  );
  return (
    <>
      {appBadges.map(
        ({ badge, bundleAppBadgePayload, discountBadgePayload }) => {
          switch (badge) {
            case AppBadgesType.BETA:
              return <BetaBadge {...{ appName, showBorder, size }} />;
            case AppBadgesType.WIX_CHOICE:
              return <WixChoiceBadge {...{ showBorder, size }} />;
            case AppBadgesType.PREMIUM_BENEFIT:
              if (replaceBundleBadge) {
                return (
                  <AppBenefitsBadge
                    {...{ appName, showBorder, size, isInstalled }}
                  />
                );
              }
              return (
                <BundleBadge
                  isCouponTypeBundle={
                    bundleAppBadgePayload?.appBundleType ===
                    BundleAppType.COUPON
                  }
                  {...{ appName, showBorder, size, isInstalled }}
                />
              );
            case AppBadgesType.APP_BENEFIT:
              return (
                <AppBenefitsBadge
                  {...{ appName, showBorder, size, isInstalled }}
                />
              );
            case AppBadgesType.WIX_SALE:
              if (showNovemberSaleBadge) {
                return (
                  <WixSaleBadge
                    amount={discountBadgePayload?.amount!}
                    size={wixSaleSize}
                  />
                );
              }
              return undefined;
            case AppBadgesType.DEV_SALE:
              if (showDeveloperSaleBadge) {
                return (
                  <DeveloperSaleBadge
                    amount={discountBadgePayload?.amount!}
                    endDate={discountBadgePayload?.endDate || undefined}
                    {...{ showBorder, size }}
                  />
                );
              }
              return undefined;
            default:
              return undefined;
          }
        },
      )}
    </>
  );
}

export default AppBadges;
