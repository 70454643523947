import React from 'react';
import type { ITraceableTrader } from '@wix/app-market-services';
import { Box, Text } from '@wix/design-system';
import { useEssentials } from '../../contexts/essentials-context';

export function TraceableTraderPopoverContent({
  phone,
  email,
  address,
  name,
}: ITraceableTrader) {
  const { t } = useEssentials();
  return (
    <Box
      dataHook="traceable-trader-disclaimer-content"
      width="100%"
      direction="vertical"
    >
      <Text size="tiny" secondary lineHeight="tiny" letterSpacing="0">
        {t('app-page.traceable-trader.popover-sub-title')}
      </Text>
      <Box direction="vertical" marginTop="SP2">
        <PopOverField
          name={t('app-page.traceable-trader.popover-name')}
          value={name}
        />
        <PopOverField
          name={t('app-page.traceable-trader.popover-phone')}
          value={phone}
        />
        <PopOverField
          name={t('app-page.traceable-trader.popover-email')}
          value={email}
          skin="primary"
        />
        <PopOverField
          name={t('app-page.traceable-trader.popover-address')}
          value={address}
        />
      </Box>
    </Box>
  );
}

function PopOverField({
  name,
  value,
  skin,
}: {
  name: string;
  value: string;
  skin?: 'primary' | 'standard';
}) {
  return (
    <Box direction="horizontal" lineHeight="15px">
      <Text size="tiny" secondary weight="thin" suffix=" ">
        {name}:
      </Text>
      <Box marginLeft="SP1">
        <Text size="tiny" secondary skin={skin} weight="thin">
          {value}
        </Text>
      </Box>
    </Box>
  );
}
