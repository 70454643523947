import React from 'react';
import { Box, Text } from '@wix/design-system';
import type { Essentials } from '../../contexts/essentials-context';
import { useEssentials } from '../../contexts/essentials-context';
import type { AppPricing } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import type { CurrencyToString } from '@wix/premium-react-text';
import { usePremiumCurrencyFormatter } from '@wix/premium-react-text';
import { getDecodedSign } from '@wix/app-market-services';
import s from './pricing-badge.module.scss';

interface IPricingBadgeProps extends Required<AppPricing> {
  size?: 'tiny' | 'small';
}
export type PricingBadgeProps = IPricingBadgeProps;

const getPricingLabel = (
  label: PricingBadgeProps['label'],
  currencyToString: CurrencyToString,
  t: Essentials['t'],
  showStrikethrough: boolean,
) => {
  switch (label.type) {
    case 'FREE':
      return t('app.pricing.free');
    case 'FREE_TO_INSTALL':
      return t('app.pricing.free-to-install');
    case 'FREE_TRIAL_DAYS':
      return t('app.pricing.trial-days-in-app', {
        trialDays: label.freeTrialDaysLabelData?.freeTrialDays,
      });
    case 'FREE_PLAN_AVAILABLE':
      return t('app.pricing.free-plan-available');
    case 'RECURRING_PRICE':
      return label.recurringPriceLabelData?.discountPrice && showStrikethrough
        ? t('app.pricing.from-in-app-full', {
            fullPrice: currencyToString(
              Number(label.recurringPriceLabelData?.discountPrice),
            ),
          })
        : t('app.pricing.from-in-app-full', {
            fullPrice: currencyToString(
              Number(label.recurringPriceLabelData?.price),
            ),
          });
    case 'ONE_TIME_PRICE':
      return t('app.pricing.one-time-price', {
        price: currencyToString(label.oneTimePriceLabelData?.price as number),
      });
    default:
      return t('app.pricing.free-to-install');
  }
};

export function PricingBadge(props: PricingBadgeProps) {
  const { t, experiments } = useEssentials();
  const { label, currencySettings, size = 'tiny' } = props;
  const showStrikethrough =
    experiments?.enabled('specs.app-market.pricingStrikethrough') ?? false;

  const [currencyToString] = usePremiumCurrencyFormatter({
    currencySettings: {
      [currencySettings?.code || '']: {
        symbol: currencySettings?.symbol ?? '',
        decimalSep: currencySettings?.decimalSeparator ?? '',
        groupSep: currencySettings?.groupSeparator ?? '',
        negPrefix: currencySettings?.negativePrefix ?? '',
        negSuffix: currencySettings?.negativeSuffix ?? '',
        posPrefix: currencySettings?.positivePrefix ?? '',
        posSuffix: currencySettings?.positiveSuffix ?? '',
        fractionSize: 2,
      },
    },
    currencyCode: currencySettings?.code ?? '',
  });

  return (
    <Box gap="6px">
      <Text
        dataHook="pricing-badge-text"
        size={size}
        weight="thin"
        skin="standard"
        secondary
      >
        {getPricingLabel(label, currencyToString, t, showStrikethrough)}
      </Text>

      {label.recurringPriceLabelData?.discountPrice && showStrikethrough && (
        <Text
          secondary
          size={size}
          weight="thin"
          className={s.priceStrikeThrough}
        >
          {`${getDecodedSign(currencySettings.symbol)} ${
            label.recurringPriceLabelData?.fullPrice
          }`}
        </Text>
      )}
    </Box>
  );
}
