import type { ReactElement } from 'react';
import React, { useMemo } from 'react';
import { useQueryClient, QueryClient, useQuery } from '@tanstack/react-query';
import { isSSR } from '@wix/app-market-services';

type QueryFn<T> = () => Promise<T>;
type ChildrenProps<T> =
  | {
      data: T;
      isLoading: false;
    }
  | {
      data: null;
      isLoading: true;
    };
type Children<T> = (props: ChildrenProps<T>) => ReactElement;

interface QueryDecoratorProps<T> {
  queryFn: QueryFn<T>;
  children: Children<T>;
  queryKey: string;
  deps?: string[];
  csrOnly?: boolean;
}

function createSSRQuery<T>(
  queryKey: string,
): ({ queryFn }: { queryFn: QueryFn<T> }) => T {
  let error: Error;
  let data: T | undefined;
  let promise: Promise<T> | undefined;

  return ({ queryFn }) => {
    const queryClient = useQueryClient();

    if (data) {
      return data;
    }

    if (error) {
      throw error;
    }

    if (!promise) {
      promise = queryClient
        .fetchQuery([queryKey], queryFn)
        .then((_data) => {
          data = _data;
          return data;
        })
        .catch((e: Error) => {
          error = e;
          throw e;
        });
    }

    throw promise;
  };
}

function SSRRenderer<T>({
  query,
  queryFn,
  children: Children,
}: {
  query: ({ queryFn }: { queryFn: QueryFn<T> }) => T;
  queryFn: QueryFn<T>;
  children: Children<T>;
}) {
  const data = query({ queryFn });

  return <Children data={data} isLoading={false} />;
}

function CSRRenderer<T>({
  queryKey,
  queryFn,
  children: Children,
  deps,
}: {
  queryKey: string;
  queryFn: QueryFn<T>;
  children: Children<T>;
  deps: string[];
}) {
  const { data, isLoading, error } = useQuery<T, Error>([queryKey], queryFn);

  const memoizedChildren = useMemo(() => {
    if (error) {
      throw error;
    }

    if (data) {
      return <Children data={data} isLoading={false} />;
    }

    return <Children data={null} isLoading={true} />;
  }, [data, isLoading, error, queryKey, ...deps]);

  return memoizedChildren;
}

/**
 * QueryDecorator for fetching data on SSR & CSR.
 */
export function QueryDecorator<T>({
  queryFn,
  children,
  queryKey,
  csrOnly = false,
  deps = [],
}: QueryDecoratorProps<T>) {
  const ssrQuery = useMemo(() => createSSRQuery<T>(queryKey), [queryKey]);

  if (isSSR() && !csrOnly) {
    return (
      <SSRRenderer<T> query={ssrQuery} queryFn={queryFn}>
        {children}
      </SSRRenderer>
    );
  }

  return (
    <CSRRenderer<T> queryKey={queryKey} queryFn={queryFn} deps={deps}>
      {children}
    </CSRRenderer>
  );
}

export async function fetchWithQuery<T>({
  queryClient,
  queryFn,
  queryKey,
}: {
  queryClient: QueryClient;
  queryFn: QueryFn<T>;
  queryKey: string;
}): Promise<T> {
  return queryClient.fetchQuery([queryKey], queryFn);
}

/**
 * use only to create a new QueryClient that is passed to QueryClientProvider,
 * (if you want to create a new QueryClient instance inside a component use useCreateQueryClient instead),
 * to get the QueryClient instance in some inner component use useQueryClient hook from react-query.
 * @returns new instance of QueryClient with shared configuration.
 */
export function createQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 15, // 15 minutes
      },
    },
  });
}

export function useCreateQueryClient(): QueryClient {
  return useMemo(() => createQueryClient(), []);
}
