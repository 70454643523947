import React from 'react';
import {
  Accordion,
  accordionItemBuilder,
  Box,
  Divider,
  Heading,
  Text,
} from '@wix/design-system';
import { ChevronDown, ChevronRight } from '@wix/wix-ui-icons-common';
import css from './app-permissions-accordion.scss';
import { useTranslation } from '../../../../contexts/translations-context';
import type { Permission } from '@wix/app-market-services';

interface AppPermissionsAccordionProps {
  appName: string;
  permissions: Permission[];
}

export function AppPermissionsAccordion({
  appName,
  permissions,
}: AppPermissionsAccordionProps) {
  const { t } = useTranslation();

  const permissionsAccordionItems = permissions.map((permission) =>
    accordionItemBuilder({
      buttonType: 'node',
      showLabel: 'always',
      title: (
        <Text size="small" weight="thin">
          {permission.title}
        </Text>
      ),
      expandLabel: <ChevronRight />,
      collapseLabel: <ChevronDown />,
      children: (
        <Text size="small" secondary>
          {permission.description}
        </Text>
      ),
    }),
  );

  if (permissions.length === 0) {
    return null;
  }

  return (
    <Box direction="vertical" gap="9px">
      <Heading size="medium">
        {t('app.page.permissions.title', {
          name: appName,
          interpolation: { escapeValue: true },
        })}
      </Heading>

      {permissions.length === 1 ? (
        <Box direction="vertical" gap="SP2">
          <Text listStyle="circle">
            <ul>
              <li>
                {permissions[0].title}

                <Box marginTop="SP1">
                  <Text secondary>{permissions[0].description}</Text>
                </Box>
              </li>
            </ul>
          </Text>
        </Box>
      ) : (
        <Box direction="vertical" className={css.permissionItem}>
          <Divider />
          <Accordion
            hideShadow
            skin="light"
            size="tiny"
            horizontalPadding="large"
            items={permissionsAccordionItems}
          />
        </Box>
      )}
    </Box>
  );
}
