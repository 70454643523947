import type { AmbassadorBootstrap } from '@wix/ambassador-bootstrap-plugin';
import type { IHttpClient } from '@wix/http-client';
import { isSSR } from '../utils';
import { HttpServices } from './http-services';
import { RpcServices } from './rpc-services';
import type { queryTag } from './tags';
import { Tags } from './tags';
import type { completeSetupCount, getManagedApps } from './managed-apps';
import { ManagedApps } from './managed-apps';
import { MarketAppService } from './market-app';
import { AppsService } from './apps-service';
import type { getAppsDataByTagId, queryTagApp } from './tag-apps';
import { APPS_LIMIT, TagApps, TagAppsService } from './tag-apps';
import type {
  CustomParams,
  getAppsByAppIds,
  getDynamicSections,
  getEligibleAppsForFreeTrial,
  Placement,
  Status,
} from './app-market-client-aggregator/types';
import { AppsData } from './app-market-client-aggregator';
import type { getWebSolutionsBase } from './web-solutions';
import { WebSolutionsBase } from './web-solutions';
import { type completePendingInstallation } from './pending-installations';
import type { PendingReason } from '@wix/ambassador-devcenter-pendingapps-v1-pending-installation/types';
import type { ComponentType } from '@wix/ambassador-devcenter-app-components-v1-app-components/types';
import type { getAppComponentsByAppIds } from './apps-components';
import { AppsComponents } from './apps-components';
import type { getTagSections } from './tag-sections';
import { TagSections } from './tag-sections';
import type { getSiteProperties } from './site-properties';
import { SiteProperties } from './site-properties';
import {
  type getSitesPremiumStatus,
  SitesPremiumStatus,
} from './premium-data-view-retriever';
import type {
  getAutoCompleteSuggestions,
  searchApps,
} from './app-market-search';
import { AutoComplete, SearchApps } from './app-market-search';
import {
  AutoComplete as AutoCompleteProxy,
  type getAutoCompleteProxySuggestions,
} from './app-market-search-proxy';
import {
  BundleApps,
  BundleAppsService,
  type getBundleApps,
} from './bundle-apps';
import type { getFreeTrialEligibility } from './premium-store';
import type { GetFreeTrialEligibilityRequest } from '@wix/ambassador-premium-store-v1-dynamic-offering-service-entity/types';
import { PlansService } from './plans';
import { AppsPlansService } from './apps-plans';
import { MarketListingService } from './market-listing';
import { GoogleAnalyticsService } from './google-analytics';
import { AppReviewsSummaryService } from './app-review-summary';
import { MyAppsService } from './my-apps/my-apps.service';
import { ReviewsService } from './review/reviews.service';
import { GetShareUrlLinkService } from './dev-center-share-url';
import { MarketSectionsService } from './market-sections';
import { TraceableTraderService } from './traceable-trader';
import type { getAppPermissions } from './app-permissions';

/**
 * Implements IServices.
 ** abstracts SSR/CSR api calls.
 ** api response returns the abstracted domain model.
 For example, getManagedApps returns ManagedApps model.
 * @class Services
 */
export class Services {
  private httpServices: HttpServices;
  private rpcServices?: RpcServices | undefined;

  constructor({
    httpClient,
    rpcClient,
  }: {
    httpClient?: IHttpClient;
    rpcClient?: AmbassadorBootstrap;
  }) {
    if (!isSSR() && !httpClient) {
      throw new Error('httpServices is not defined');
    }
    this.httpServices = new HttpServices(httpClient!);
    this.rpcServices = isSSR() ? new RpcServices(rpcClient!) : undefined;
    this.marketListing = new MarketListingService(rpcClient, httpClient);
    this.plans = new PlansService(rpcClient, httpClient);
    this.appPlans = new AppsPlansService(rpcClient, httpClient);
    this.reviews = new ReviewsService(rpcClient, httpClient);
    this.myApps = new MyAppsService(rpcClient, httpClient);
    this.appReviewsSummary = new AppReviewsSummaryService(
      rpcClient,
      httpClient,
    );
    this.devCenterShareUrl = new GetShareUrlLinkService(rpcClient, httpClient);
    this.marketApp = new MarketAppService(rpcClient, httpClient);
    this.marketSections = new MarketSectionsService(rpcClient, httpClient);
    this.googleAnalytics = new GoogleAnalyticsService(rpcClient, httpClient);
    this.tagApps = new TagAppsService(rpcClient, httpClient);
    this.bundleApps = new BundleAppsService(rpcClient, httpClient);
    this.apps = new AppsService(rpcClient, httpClient);
    this.traceableTrader = new TraceableTraderService(rpcClient, httpClient);
  }

  public marketListing: MarketListingService;
  public plans: PlansService;
  public appPlans: AppsPlansService;
  public reviews: ReviewsService;
  public myApps: MyAppsService;
  public appReviewsSummary: AppReviewsSummaryService;
  public devCenterShareUrl: GetShareUrlLinkService;
  public marketApp: MarketAppService;
  public marketSections: MarketSectionsService;
  public googleAnalytics: GoogleAnalyticsService;
  public tagApps: TagAppsService;
  public bundleApps: BundleAppsService;
  public apps: AppsService;
  public traceableTrader: TraceableTraderService;

  getBundleApps: getBundleApps = async () => {
    if (this.rpcServices) {
      const response = await this.rpcServices?.getBundleApps({});
      return new BundleApps(response?.bundleApps?.bundleWebSolutions || []);
    } else {
      throw new Error(
        "getBundleApps doesn't support HttpService, please use RPCservice.",
      );
    }
  };

  getManagedApps: getManagedApps = async (payload) => {
    const response = this.rpcServices
      ? await this.rpcServices?.getManagedApps(payload)
      : (await this.httpServices.getManagedApps(payload)).data;

    return new ManagedApps(response.managedApps || []);
  };

  completeSetupCount: completeSetupCount = async (payload) => {
    const response = this.rpcServices
      ? await this.rpcServices?.completeSetupCount(payload)
      : (await this.httpServices.completeSetupCount(payload)).data;

    return response.amountOfApps ?? 0;
  };

  queryTag: queryTag = async (payload) => {
    const {
      tagIds: id,
      slugs: slug,
      languageCode,
      isHidden,
      tagType,
    } = payload;

    const req = {
      query: {
        filter: {
          ...(id ? { id } : {}),
          ...(slug ? { slug } : {}),
          ...(isHidden !== undefined ? { isHidden } : {}),
          ...(tagType ? { tagType } : {}),
        },
      },
      languageCode,
    };
    const response = this.rpcServices
      ? await this.rpcServices?.queryTag(req)
      : (await this.httpServices.queryTag(req)).data;

    return new Tags(response.tags || []);
  };

  queryTagApp: queryTagApp = async (payload) => {
    const { appIds: appId, tagIds: tagId } = payload;
    const req = {
      query: {
        filter: {
          ...(appId ? { appId } : {}),
          ...(tagId ? { tagId } : {}),
        },
      },
    };

    const response = this.rpcServices
      ? await this.rpcServices?.queryTagApp(req)
      : (await this.httpServices.queryTagApp(req)).data;

    return new TagApps(response.tagApps || []);
  };

  getAppsByAppIds: getAppsByAppIds = async (payload) => {
    if (payload.appIds.length === 0) {
      return new AppsData([]);
    }
    const { appIds, status } = payload;
    const req = {
      appIds,
      status: status as Status,
    };

    const response = this.rpcServices
      ? await this.rpcServices?.getAppsByAppIds(req)
      : (await this.httpServices.getAppsByAppIds(req)).data;

    return new AppsData(response.apps || []);
  };

  getDynamicSections: getDynamicSections = async (payload) => {
    const { placement, isMetaSiteId } = payload;
    const req: {
      placement: Placement;
      customParams?: CustomParams;
    } = {
      placement: placement as Placement,
      customParams: {
        isMetaSiteId: JSON.stringify(isMetaSiteId),
      },
    };

    const response = this.rpcServices
      ? await this.rpcServices?.getDynamicSections(req)
      : (await this.httpServices.getDynamicSections(req)).data;

    return response;
  };

  getWebSolutionsBase: getWebSolutionsBase = async (payload) => {
    const req = {
      idsOrSlugs: payload.appIds.join(','),
    };
    const response = this.rpcServices
      ? await this.rpcServices?.getWebSolutionsBase(req)
      : (await this.httpServices.getWebSolutionsBase(req)).data;

    return new WebSolutionsBase(response.webSolutionsBase || []);
  };

  completePendingInstallation: completePendingInstallation = async (
    payload,
  ) => {
    const req = {
      appId: payload.appId,
      pendingReason: payload.pendingReason as PendingReason,
    };

    this.rpcServices
      ? this.rpcServices?.completePendingInstallation(req)
      : this.httpServices.completePendingInstallation(req);
  };

  getTagSections: getTagSections = async (payload) => {
    if (!payload.tagIds.length) {
      throw new Error('tagIds empty in getTagSections req');
    }
    const response = this.rpcServices
      ? await this.rpcServices?.getTagSections(payload)
      : (await this.httpServices.getTagSections(payload)).data;

    return new TagSections(response || []);
  };

  getSiteProperties: getSiteProperties = async () => {
    if (this.rpcServices) {
      throw new Error("getSiteProperties doesn't support RPC.");
    }
    const response = await this.httpServices.getSiteProperties();
    return new SiteProperties(response?.properties);
  };

  getSitesPremiumStatus: getSitesPremiumStatus = async (payload) => {
    const response = this.rpcServices
      ? await this.rpcServices?.getSitesPremiumStatus(payload)
      : (await this.httpServices.getSitesPremiumStatus(payload)).data;

    return new SitesPremiumStatus(response.sitesPremiumStatus || []);
  };

  searchApps: searchApps = async (payload) => {
    const { paging, filter, searchTerm } = payload;
    const req = {
      searchTerm,
      paging,
      ...(filter?.isSale
        ? { filter: { saleType: { $ne: 'SALE_TYPE_UNSPECIFIED' } } }
        : {}),
    };
    const response = this.rpcServices
      ? await this.rpcServices?.searchApps(req)
      : (await this.httpServices.searchApps(req)).data;

    return new SearchApps(response.webSolutions || [], response.paging);
  };

  getAppsDataByTagId: getAppsDataByTagId = async (payload) => {
    const { tagId, sortTypes, limit, offset = 0, status, filter } = payload;
    const tagApps = await this.tagApps.getAppsByTag({
      tagId,
      sortTypes,
      paging: {
        limit: limit && limit < APPS_LIMIT ? limit : APPS_LIMIT,
        offset,
      },
      ...(filter ? { filter } : {}),
    });
    const appsData = await this.getAppsByAppIds({
      appIds: tagApps.appIds,
      status,
    });
    return {
      apps: appsData.toJSON(),
      hasNext: tagApps.paging.hasNext,
      total: tagApps.paging.total,
    };
  };

  getAppComponentsByAppIds: getAppComponentsByAppIds = async (payload) => {
    const { appId, componentTypes } = payload;
    const getAppComponentsByAppIdsRequest = {
      apps: [
        {
          appId,
        },
      ],
      componentTypes: componentTypes as ComponentType[],
    };
    const response = this.rpcServices
      ? await this.rpcServices?.getAppComponentsByAppIds(
          getAppComponentsByAppIdsRequest,
        )
      : (
          await this.httpServices.getAppComponentsByAppIds(
            getAppComponentsByAppIdsRequest,
          )
        ).data;

    return new AppsComponents(response.appComponents || []);
  };

  getAutoCompleteProxySuggestions: getAutoCompleteProxySuggestions = async (
    payload,
  ) => {
    if (this.rpcServices) {
      throw new Error("getAutoCompleteProxySuggestions doesn't support RPC.");
    }
    const { searchTerm, languageCode } = payload;

    const response = await this.httpServices.getAutoCompleteProxySuggestions({
      searchTerm,
      languageCode: languageCode ?? 'en',
    });
    return new AutoCompleteProxy(response);
  };

  getAutoCompleteSuggestions: getAutoCompleteSuggestions = async (payload) => {
    const { searchTerm, paging } = payload;
    const getAutoCompleteSuggestionsRequest = {
      searchTerm,
      paging,
    };
    const response = this.rpcServices
      ? await this.rpcServices?.getAutoCompleteSuggestions(
          getAutoCompleteSuggestionsRequest,
        )
      : (
          await this.httpServices.getAutoCompleteSuggestions(
            getAutoCompleteSuggestionsRequest,
          )
        ).data;

    return new AutoComplete(response.suggestions || []);
  };

  getFreeTrialEligibility: getFreeTrialEligibility = async (payload) => {
    const getFreeTrialEligibilityRequest: GetFreeTrialEligibilityRequest = {
      productTypeId: '09ffaddc-9188-46f1-a8ad-33402da0907d',
      productFamilyId: payload.appId,
    };
    const response = this.rpcServices
      ? await this.rpcServices?.getFreeTrialEligibility(
          getFreeTrialEligibilityRequest,
        )
      : (
          await this.httpServices.getFreeTrialEligibility(
            getFreeTrialEligibilityRequest,
          )
        ).data;

    return !!response.freeTrialEligibility?.eligible;
  };

  getEligibleAppsForFreeTrial: getEligibleAppsForFreeTrial = async () => {
    const { eligibleAppIds } = this.rpcServices
      ? await this.rpcServices?.getEligibleAppsForFreeTrial({})
      : (await this.httpServices.getEligibleAppsForFreeTrial({})).data;

    return eligibleAppIds ?? [];
  };

  getAppPermissions: getAppPermissions = async (payload) => {
    if (this.rpcServices) {
      throw new Error("getSiteProperties doesn't support RPC.");
    }

    return this.httpServices.getAppPermissions(payload);
  };
}
