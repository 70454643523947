import type { PathMatch } from 'react-router-dom';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { Route } from '@wix/app-market-core';
import type { IRoute, QueryMap } from '@wix/app-market-core';

function parseRouteMatch(match: PathMatch<'path' | 'slug' | 'subSlug'> | null) {
  const path = match?.params?.path || '';
  const slug = match?.params?.slug;
  const subSlug = match?.params?.subSlug;
  return { path, slug, subSlug };
}

const usePayload = (): Partial<QueryMap> => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const payload: Partial<QueryMap> = {};

  for (const [key, value] of urlParams.entries()) {
    payload[key as keyof QueryMap] = value;
  }

  return payload;
};

export function useRoute({ baseURL }: { baseURL: string }): {
  route: IRoute;
  setRoute: (route: IRoute, options?: { replace?: boolean }) => void;
  getHrefByRouteAndLanguage: (r: IRoute, languageCode: string) => string;
} {
  const navigate = useNavigate();
  const payload = usePayload();
  const match = useMatch('/:path?/:slug?/:subSlug?');

  const { path, slug, subSlug } = parseRouteMatch(match);
  const route = Route.toRoute({
    path,
    payload,
    slug,
    subSlug,
    baseURL,
  }).toJSON();

  const setRoute = (r: IRoute, options?: { replace?: boolean }): void => {
    const newRoute = new Route({
      path: r.path,
      payload: r.payload,
      baseURL,
    });
    navigate(
      {
        pathname: newRoute.toPathname(),
        search: newRoute.toSearch(),
      },
      options,
    );

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getHrefByRouteAndLanguage = (
    r: IRoute,
    languageCode: string,
  ): string => {
    const newRoute = new Route({
      path: r.path,
      payload: r.payload,
      baseURL:
        languageCode === 'en'
          ? 'https://www.wix.com/app-market'
          : `https://${languageCode}.wix.com/app-market`,
    });

    return newRoute.toHref();
  };

  return {
    route,
    setRoute,
    getHrefByRouteAndLanguage,
  };
}
