import * as React from 'react';
import { ClientSDK, Platform } from '@wix/installation-manager-client-sdk';
import type {
  IAppMarketParams,
  IMarketplaceProps,
  IRoute,
  StatusCode,
} from '@wix/marketplace-component';
import {
  AppPageMode,
  Marketplace,
  Origin,
  Path,
  PublicDevCenterBanner,
} from '@wix/marketplace-component';
import type { NavigateFunction, Location, PathMatch } from 'react-router';
import {
  Route,
  useMatch,
  useLocation,
  useNavigate,
  Routes,
} from 'react-router';
import queryString from 'query-string';
import jwtDecode from 'jwt-decode';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { withExperiments } from '@wix/wix-experiments-react';
import type { i18n as i18nType } from 'i18next';
import { showToast } from '../../utils/showToast';
import s from './App.scss';
import type { IHttpClient } from '@wix/http-client';
import { HttpClient } from '@wix/http-client';
import '@wix/design-system/styles.global.css';
import { Box } from '@wix/design-system';
import webBiLogger from '@wix/web-bi-logger';
import type { AmbassadorBootstrap } from '@wix/ambassador-bootstrap-plugin';
import type { BiLogger } from '@wix/app-market-core';
import {
  EssentialsContextProvider,
  InstallerContextProvider,
  UserContextProvider,
  ServicesContextProvider,
  SiteContextProvider,
  RouterContextProvider,
} from '@wix/app-market-core';
import { Services } from '@wix/app-market-services';
import { Router } from '../../router';
import { Installer } from '../../installer';
import { useRoute } from '../../hooks/use-route';
import { SEOContainer } from '../seo/seo-container';
import { MarketWatch, MarketWatchPlatform } from '@wix/app-market-watch';
import {
  GoogleAnalytics,
  AppMarketAnalyticsPlatform,
} from '@wix/app-market-analytics';
import { useInstallApp } from '@wix/app-installer';

interface IAppProps extends Partial<InjectedExperimentsProps> {
  routeBaseName: string;
  locale: string;
  appMarketParams?: IAppMarketParams;
  marketplaceStaticsUrl: string;
  manageAppsStaticsUrl: string;
  baseURL?: string;
  ssrMemo?: any;
  isMobile?: boolean;
  i18n: i18nType;
  baseApiUrl?: string;
  metaTagsAggregator: string[];
  countryCode?: string;
  userName?: string;
  userImage?: string;
  userId?: string;
  status: { code: number };
  rpcClient: IMarketplaceProps['rpcClient'];
  ambassadorClient?: AmbassadorBootstrap;
  artifactVersion: string;
}

class App extends React.Component<IAppProps> {
  private readonly biLogger: BiLogger;
  private readonly httpClient: IHttpClient;
  private readonly clientSDK: ClientSDK;
  private readonly marketWatch: MarketWatch;
  private readonly googleAnalytics: GoogleAnalytics;

  constructor(props) {
    super(props);
    this.httpClient = new HttpClient();
    this.biLogger = webBiLogger.factory().logger();
    this.biLogger.updateDefaults({
      market: Origin.STANDALONE,
      in_context_view: false,
    });
    this.clientSDK = new ClientSDK({
      platform: Platform.STANDALONE,
      httpClient: this.httpClient,
    });
    this.marketWatch = new MarketWatch({
      platform: MarketWatchPlatform.Standalone,
      fullArtifactId: 'com.wixpress.marketplace-standalone',
      artifactVersion: props.artifactVersion,
      sentryDsn:
        'https://65143368c92548d1b1570ca0fd0c3149@sentry-next.wixpress.com/17823',
    });
    this.marketWatch.updateGlobalParams({
      languageCode: props.locale,
    });
    this.googleAnalytics = new GoogleAnalytics(
      AppMarketAnalyticsPlatform.Standalone,
      this.props.isMobile ? 'mobile' : 'desktop',
      this.props.userId,
      undefined,
    );
  }

  getUrlObject = (route: IRoute): string => {
    const getPathname = () => {
      if (route.path === Path.WEB_SOLUTION && route.slug) {
        return `${route.slug}`;
      }
      if (
        route.path === Path.CATEGORY &&
        route.subCategories &&
        Boolean(route.subCategories.length)
      ) {
        const subCatUri = route.subCategories.sort().join('__');
        return `${route.path}/${route.slug}/${subCatUri}`;
      }
      if (route.slug) {
        return `${route.path}/${route.slug}`;
      }
      return route.path;
    };

    const pathname = getPathname();
    const searchParams: Set<string> = new Set([]);
    [
      'query',
      'referral',
      'appIndex',
      'referralTag',
      'collimp_id',
      'referralSectionName',
      'searchLocation',
      'utm_id',
    ].forEach((param) => {
      if (
        (Boolean(route[param]) || param === 'appIndex') &&
        route[param] !== undefined &&
        !Number.isNaN(route[param])
      ) {
        searchParams.add(`${[param]}=${route[param]}`);
      }
    });

    const search = searchParams.size
      ? `?${Array.from(searchParams.values()).join('&')}`
      : '';
    return `/${pathname}${search}`;
  };

  getMarketplaceProps = (
    navigate: NavigateFunction,
    currentRoute: IRoute,
    location: Location,
  ): IMarketplaceProps => {
    const {
      locale,
      marketplaceStaticsUrl,
      manageAppsStaticsUrl,
      experiments,
      isMobile,
      i18n,
      baseApiUrl,
      countryCode,
      userName,
      userImage,
      status,
      rpcClient,
    } = this.props;

    const { siteId } = queryString.parse(location.search);
    if (siteId) {
      this.clientSDK.setMetaSiteId(siteId?.toString());
    }
    return {
      goto: (route: IRoute) => {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
          window.scrollTo(0, 0);
        }
        navigate(this.getUrlObject(route));
      },
      responseCode: (code: StatusCode) => {
        const isBrowser = typeof window !== 'undefined';
        if (!isBrowser && status) {
          status.code = code;
        }
      },
      locale,
      baseApiUrl: baseApiUrl || 'https://www.wix.com',
      origin: Origin.STANDALONE,
      experimentsBag: experiments.all(),
      route: currentRoute,
      manageAppsStaticsUrl,
      marketplaceStaticsUrl,
      installApp: this.clientSDK.installApp,
      ssrMemo: this.props.ssrMemo || {},
      metaTagsAggregator: this.props.metaTagsAggregator,
      showHeader:
        currentRoute.path !== Path.SHARE &&
        currentRoute.path !== Path.UNLISTED_APPS,
      hideSearchInMenu: true,
      isMobile,
      i18n,
      countryCode,
      userName,
      userImage,
      showUserActionNotification: showToast,
      rpcClient,
    };
  };

  parseAppMarketParams = (appMarketParamsKey) => {
    let appMarketParams;
    try {
      appMarketParams = jwtDecode<any>(appMarketParamsKey);
      if (appMarketParams && typeof appMarketParams.data === 'string') {
        const parsedParams = JSON.parse(appMarketParams.data);
        return parsedParams.languageCode
          ? parsedParams
          : { ...parsedParams, languageCode: 'en' };
      }
    } catch (e) {
      console.log('error in appMarketParams');
    }

    return appMarketParams;
  };

  getSlug(slugParam: string, pathParam: string) {
    if (slugParam) {
      return slugParam;
    }
    const pathList = [
      Path.CATEGORY.toString(),
      Path.SUB_CATEGORY.toString(),
      Path.COLLECTION.toString(),
      Path.FINISH_SETUP.toString(),
      Path.HOME.toString(),
      Path.MANAGE_APPS.toString(),
      Path.RECOMMENDATION.toString(),
      Path.SEARCH.toString(),
      Path.SHARE.toString(),
      Path.UNLISTED_APPS.toString(),
      Path.WEB_SOLUTION.toString(),
      Path.DEVELOPER.toString(),
    ];
    if (!pathList.includes(pathParam)) {
      return pathParam;
    }
    return '';
  }

  getPath(path: string) {
    if (!path || path === 'main') {
      return Path.HOME;
    }
    switch (path) {
      case Path.CATEGORY.toString():
        return Path.CATEGORY;
      case Path.SUB_CATEGORY.toString():
        return Path.SUB_CATEGORY;
      case Path.COLLECTION.toString():
        return Path.COLLECTION;
      case Path.SEARCH.toString():
        return Path.SEARCH;
      case Path.WEB_SOLUTION.toString():
        return Path.WEB_SOLUTION;
      case Path.SHARE.toString():
        return Path.SHARE;
      case Path.HOME.toString():
        return Path.HOME;
      case Path.DEVELOPER.toString():
        return Path.DEVELOPER;
      case Path.UNLISTED_APPS.toString():
        return Path.UNLISTED_APPS;
      default:
        return Path.WEB_SOLUTION;
    }
  }

  getPathFromAppMarketParams(appMarketParams: any): IRoute {
    const appMarketParamsJson = this.parseAppMarketParams(appMarketParams);

    if (appMarketParamsJson) {
      const { route, shareId, appDefId, version, appId, languageCode } =
        appMarketParamsJson;
      const Id = appDefId || appId; // :(
      if (Id && !route) {
        return {
          path: Path.FINISH_SETUP,
          slug: Id,
        };
      }

      if (Id && shareId && route === 'shareApp') {
        return {
          path: Path.SHARE,
          shareAppData: { appDefId: Id, shareId, version },
        };
      }
      if (Id && route === 'testApp') {
        return {
          path: Path.WEB_SOLUTION,
          slug: Id,
          version,
          appPageMode: AppPageMode.TEST,
          languageCode,
        };
      }

      if (Id && route === 'preview') {
        return {
          path: Path.WEB_SOLUTION,
          slug: Id,
          appPageMode: AppPageMode.PREVIEW,
          languageCode,
        };
      }
    }
    return { path: Path.HOME };
  }

  calcRoute(
    match: PathMatch<'path' | 'subslug' | 'slug'>,
    location: Location,
  ): IRoute {
    const {
      query,
      referral,
      referralInfo,
      appMarketParams,
      AppMarketParams,
      platformName,
      appIndex,
      referralTag,
      collimp_id,
      referralSectionName,
      searchLocation,
      utm_id,
    } = queryString.parse(location.search);

    if (appMarketParams || AppMarketParams) {
      return this.getPathFromAppMarketParams(
        appMarketParams || AppMarketParams,
      );
    }
    const path: Path = this.getPath(match.params.path);
    const getSubcategories = () => {
      if (path === Path.CATEGORY && match.params.subslug) {
        return [match.params.subslug];
      }
    };

    const subCategories = getSubcategories();
    const subDomain = this.props.locale === 'en' ? 'www' : this.props.locale;
    const currentUrl = `https://${subDomain}.wix.com/app-market${location.pathname}${location.search}`;
    const slug = this.getSlug(match.params.slug, match.params.path);

    return {
      path,
      slug,
      query: query as string,
      referral: (referral || referralInfo) as string,
      subCategories,
      platformName: platformName as string,
      currentUrl,
      appIndex: Number(appIndex),
      referralTag: referralTag as string,
      collimp_id: collimp_id as string,
      referralSectionName: referralSectionName as string,
      searchLocation: searchLocation as string,
      utm_id: utm_id as string,
    };
  }
  render() {
    return (
      <Routes>
        <Route
          path="/:path?/:slug?/:subslug?"
          Component={() => {
            const match = useMatch('/:path?/:slug?/:subslug?');
            const location = useLocation();
            const navigate = useNavigate();
            const route = this.calcRoute(match, location);
            const path = this.getPath(route.path);
            const marketplaceProps = this.getMarketplaceProps(
              navigate,
              route,
              location,
            );

            return (
              <AppMarket
                marketplaceProps={marketplaceProps}
                userName={this.props.userName}
                countryCode={this.props.countryCode}
                biLogger={this.biLogger}
                i18n={this.props.i18n}
                ambassadorClient={this.props.ambassadorClient}
                experiments={this.props.experiments}
                route={route}
                oldBaseURL={this.props.baseURL}
                path={path}
                httpClient={this.httpClient}
                marketWatch={this.marketWatch}
                googleAnalytics={this.googleAnalytics}
                clientSDK={this.clientSDK}
                languageCode={this.props.locale}
              />
            );
          }}
        />
      </Routes>
    );
  }
}

interface Props {
  marketplaceProps: IMarketplaceProps;
  userName?: string;
  userId?: string;
  countryCode?: string;
  biLogger: BiLogger;
  i18n: i18nType;
  httpClient: IHttpClient;
  marketWatch: MarketWatch;
  googleAnalytics: GoogleAnalytics;
  ambassadorClient?: AmbassadorBootstrap;
  experiments: InjectedExperimentsProps['experiments'];
  route: IRoute;
  oldBaseURL: string;
  path: any;
  clientSDK: ClientSDK;
  languageCode: string;
}

function AppMarket({
  marketplaceProps,
  userName,
  userId,
  countryCode,
  biLogger,
  i18n,
  httpClient,
  marketWatch,
  googleAnalytics,
  ambassadorClient,
  experiments,
  route,
  oldBaseURL,
  path,
  clientSDK,
  languageCode,
}: Props) {
  const baseURL = `https://${
    languageCode === 'en' ? 'www' : languageCode
  }.wix.com/app-market`;
  const {
    route: newRoute,
    setRoute,
    getHrefByRouteAndLanguage,
  } = useRoute({
    baseURL,
  });
  const newInstallerInstallApp = useInstallApp({
    httpClient,
    i18n,
    platform: 'MARKETPLACE_STANDALONE',
  });
  const essentials = React.useMemo(
    () => ({
      biLogger,
      i18n,
      httpClient,
      rpcClient: ambassadorClient,
      experiments,
      marketWatch,
      googleAnalytics,
    }),
    [],
  );

  return (
    <div className={s.root}>
      <UserContextProvider
        user={{
          isLoggedIn: !!userName,
          isMobile: marketplaceProps?.isMobile,
          id: userId,
        }}
      >
        <SiteContextProvider
          site={{
            countryCode,
            isPremium: false,
            baseURL,
            metaTagsAggregator: marketplaceProps?.metaTagsAggregator,
            languageCode,
          }}
        >
          <EssentialsContextProvider essentials={essentials}>
            <ServicesContextProvider
              services={
                new Services({
                  httpClient,
                  rpcClient: ambassadorClient,
                })
              }
            >
              <InstallerContextProvider
                installer={
                  new Installer({
                    clientSDK,
                    newInstallerInstallApp,
                    experiments,
                    httpClient,
                    i18n,
                    installedVersionOverride: route.version,
                    referredByAppId: route.referral?.includes?.(
                      'app-dependency-',
                    )
                      ? route.referral.substring(15)
                      : undefined,
                  })
                }
              >
                <RouterContextProvider
                  router={{
                    router: new Router(setRoute),
                    route: newRoute,
                  }}
                >
                  <>
                    <SEOContainer
                      newRoute={newRoute}
                      metaTagsAggregator={marketplaceProps.metaTagsAggregator}
                      getHrefByRouteAndLanguage={getHrefByRouteAndLanguage}
                      t={(k, o) => i18n.t(k, o)}
                    />
                    <Marketplace {...marketplaceProps} baseUrl={oldBaseURL} />
                  </>
                </RouterContextProvider>
              </InstallerContextProvider>
            </ServicesContextProvider>
          </EssentialsContextProvider>
        </SiteContextProvider>
        {path !== Path.SHARE && path !== Path.UNLISTED_APPS && (
          <PublicDevCenterBanner t={(k) => i18n.t(k)} />
        )}
        <Box />
      </UserContextProvider>
    </div>
  );
}

export default withExperiments(App);
