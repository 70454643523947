import React from 'react';
import { Badge } from '.';
import { useEssentials } from '../../contexts/essentials-context';
import { Box, Text } from '@wix/design-system';

interface DeveloperSaleBadgeProps {
  showBorder?: boolean;
  size?: 'tiny' | 'small' | 'medium';
  amount: string;
  endDate?: Date;
}

export function DeveloperSaleBadge({
  showBorder,
  size = 'small',
  amount,
  endDate,
}: DeveloperSaleBadgeProps) {
  const { t } = useEssentials();
  return (
    <Box marginTop="2px">
      <Badge
        skin="standard"
        type="outlined"
        dataHook="dev-sale-badge"
        uppercase={false}
        showBorder={showBorder}
        size={size}
        tooltipContent={
          <Text size="small" weight="thin" light>
            {t('developer.sale.badge.tooltip', {
              amount,
              endDate: endDate
                ? endDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    timeZone: 'UTC',
                  })
                : '',
            })}
          </Text>
        }
      >
        <Box marginTop="0px" color="#116DFF" fontSize="small">
          - {amount}%
        </Box>
      </Badge>
    </Box>
  );
}
