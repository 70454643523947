import { RequestService } from '../request-service';
import type {
  TraceableTrader as TraceableTraderType,
  GetPublicTraceableTraderRequest,
  GetPublicTraceableTraderResponse,
} from '@wix/ambassador-partners-traceability-v1-traceable-trader/types';
import type RpcModule from '@wix/ambassador-partners-traceability-v1-traceable-trader/rpc';
import type HttpModule from '@wix/ambassador-partners-traceability-v1-traceable-trader/http';
import { TraceableTrader } from './traceable-trader';

export type GetTraceableTrader = {
  id: string;
};
type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class TraceableTraderService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import(
      '@wix/ambassador-partners-traceability-v1-traceable-trader/rpc'
    );
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import(
      '@wix/ambassador-partners-traceability-v1-traceable-trader/http'
    );
  }

  public async get({ id }: GetTraceableTrader) {
    let traceableTrader: TraceableTraderType | undefined;
    try {
      const response = await this.request<
        GetPublicTraceableTraderRequest,
        GetPublicTraceableTraderResponse
      >({
        methodName: 'getPublicTraceableTrader',
        payload: {
          publicTraceableTraderId: id,
        },
      });
      traceableTrader = response.traceableTrader;
    } catch (error) {
      console.error(`[traceable-trader.service.ts] (get) error: `, error);
    }

    if (!traceableTrader) {
      return null;
    }
    return new TraceableTrader(traceableTrader).toJSON();
  }
}
