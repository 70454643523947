import type {
  AppMarketAnalyticsPlatform,
  CustomEventsParamsMap,
  CustomEvent,
  PageViewParams,
  DefaultParams,
} from './types';

const GA_DISABLE_TRACKING_ID = (trackingId: string) =>
  `ga-disable-${trackingId}`;

export class GoogleAnalytics {
  private platform: AppMarketAnalyticsPlatform;
  private deviceType: 'desktop' | 'mobile';
  private userId: string | undefined;
  private siteId: string | undefined;
  private isInitialized: boolean;
  private trackingId: string | undefined;
  private defaultParams: DefaultParams | undefined;

  constructor(
    platform: AppMarketAnalyticsPlatform,
    deviceType: 'desktop' | 'mobile',
    userId: string | undefined,
    siteId: string | undefined,
  ) {
    this.platform = platform;
    this.deviceType = deviceType;
    this.userId = userId;
    this.siteId = siteId;
    this.isInitialized = false;
  }

  initialize(trackingId: string) {
    if (!trackingId) {
      throw new Error('Google Analytics tracking ID is required.');
    }
    if (this.isInitialized) {
      return;
    }

    this.loadGAScript(trackingId);
    this.trackingId = trackingId;
    this.defaultParams = {
      send_to: this.trackingId,
      deviceType: this.deviceType,
      platform: this.platform,
      user: this.userId,
      siteId: this.siteId,
    };
    this.isInitialized = true;
  }

  private loadGAScript = (trackingId: string) => {
    const script = document.createElement('script');
    const src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    const isScriptExist = Boolean(
      document.querySelector(`script[src="${src}"]`),
    );
    (window as any)[GA_DISABLE_TRACKING_ID(trackingId)] = false;
    if (!isScriptExist) {
      script.async = true;
      script.src = src;
      document.body.appendChild(script);

      if (typeof window.gtag === 'undefined') {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function gtag() {
          window.dataLayer.push(arguments);
        };
      }

      this.gtag('js', new Date());
      this.gtag('config', trackingId, {
        send_page_view: false,
      });
    }
  };

  private gtag = (...args: any[]) => {
    window.gtag(...args);
  };

  reset() {
    if (!this.isInitialized) {
      return;
    }

    if (this.trackingId) {
      (window as any)[GA_DISABLE_TRACKING_ID(this.trackingId)] = true;
    }
    this.isInitialized = false;
    this.trackingId = undefined;
    this.defaultParams = undefined;
  }

  reportPageView({ params }: { params: PageViewParams }) {
    if (!this.isInitialized) {
      console.error('Google Analytics is not initialized.');
      return;
    }

    this.gtag('event', 'page_view', {
      ...params,
      ...this.defaultParams,
    });
  }

  reportCustomEvent({
    name,
    category,
    params,
  }: {
    name: CustomEvent;
    category: string;
    params: CustomEventsParamsMap[CustomEvent];
  }) {
    if (!this.isInitialized) {
      console.error('Google Analytics is not initialized.');
      return;
    }

    if (!name) {
      console.error('Event name is required and cannot be empty.');
      return;
    }

    this.gtag('event', name, {
      ...params,
      category,
      ...this.defaultParams,
    });
  }
}
